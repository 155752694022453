.grid {
    --grid-color: rgba(115,59,139,.7);
    --grid-size: 60px; // stylelint-disable-line
    --grid-blur: 1px; // stylelint-disable-line
    animation: moving-grid .5s infinite linear;
    background-image: repeating-linear-gradient(90deg,var(--grid-color,black) 0%,transparent calc(1px + var(--grid-blur,0px)), transparent var(--grid-size),var(--grid-color,black) calc(var(--grid-size) + 1px + var(--grid-blur,0px))),repeating-linear-gradient(180deg,var(--grid-color,black) 0%,transparent calc(1px + var(--grid-blur,0px)), transparent var(--grid-size),var(--grid-color,black) calc(var(--grid-size) + 1px + var(--grid-blur,0px))); // stylelint-disable-line
    background-position-y: 0;
    bottom: -30%;
    height: 100%;
    left: 0;
    margin-left: -50%;
    position: absolute;
    transform: perspective(50vh) rotateX(60deg) translateZ(pxToRem(10));
    width: 200%;
    z-index: -1;

    // &::after {
    //     -webkit-backface-visibility: hidden;
    //     backface-visibility: hidden;
    //     -webkit-background-clip: content-box;
    //     background-clip: content-box;
    //     background-image: linear-gradient(to right, blue pxToRem(2), transparent pxToRem(2)),
    //         linear-gradient(to bottom, blue pxToRem(1), transparent pxToRem(2));
    //     background-position: center bottom;
    //     background-size: pxToRem(40) pxToRem(40);
    //     bottom: 0;
    //     content: '';
    //     display: block;
    //     height: 100vh;
    //     left: 0;
    //     -webkit-mask-image: -webkit-gradient(linear, left 90%, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    //     mask-image: gradient(linear, left 90%, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    //     outline: pxToRem(1) solid transparent;
    //     padding: pxToRem(1);
    //     position: absolute;
    //     right: 0;
    //     transform: perspective(pxToRem(200)) rotateX(40deg) scale(2,1) translateZ(0);
    //     transform-origin: bottom center;
    //     width: 100%;
    //     will-change: transform;
    // }
}

@keyframes moving-grid {
    0% {
        transform: perspective(50vh) rotateX(60deg) translateZ(pxToRem(10)) translateY(-var(--grid-size)); }
    100% {
        transform: perspective(50vh) rotateX(60deg) translateZ(pxToRem(10)) translateY(var(--grid-size));  }
}

.sun {
    background-color: yellow;
    background-image: linear-gradient(red,yellow),linear-gradient(black,white);
    border-radius: 100%;
    bottom: 40%;
    box-shadow: rgba(255,128,0,.7) 0 0 pxToRem(20);
    clip-path: polygon(-50% 0,150% -50%,150% 55%,-50% 55%,
    -50% 62%,150% 62%,150% 70%,0 70%,
    -50% 75%,150% 75%,150% 80%,0 80%,
    -50% 82%,150% 82%,150% 85%,0 85%,
    -50% 87%,150% 87%,150% 90%,0 90%,
    -50% 92%,150% 92%,150% 95%,0 95%,
    -50% 96%,150% 96%,150% 150%,0 150%);
    height: pxToRem(200);
    left: calc(50%);
    position: absolute;
    transform: translateX(-50%);
    width: pxToRem(200);
}

.overlay {
    background-image: repeating-linear-gradient(
        rgba(0,0,0,.3) 0,
        transparent pxToRem(1),
        transparent pxToRem(2),
        rgba(0,0,0,.3) pxToRem(3)
    );
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.background {
    --background-height: 100vh;
    background: linear-gradient(to bottom, #010310 0,#0c1142 24%,#45125e 45%,#d53567 60%,#f0c3d9 65%,#0c1142 65%) fixed;
    background-size: 100% var(--background-height,100vh);
    height: var(--background-height,100vh);
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -2;
}

.background::before {
    background: linear-gradient(to bottom, #010310 0,#0c1142 24%,#45125e 45%,#d53567 60%,#f0c3d9 65%,#0c1142 65%) fixed;
    content: '';
    height: 55%;
    left: 0;
    opacity: .2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
