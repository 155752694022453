@import './variables.scss';

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: $base-font-pixel;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

code {
    font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
}

.main-wrapper {
    left: 50%;
    min-width: pxToRem(400);
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 40%;
    transform: translate(-50%, -50%);
}

.name {
    color: #d1d1d1;
    font-size: pxToRem(26);
    text-align: center;
}

.placeholder {
    display: flex;
    flex-direction: row;
    height: pxToRem(56);
    justify-content: center;
}

.icons-wrapper {
    animation: fadein 2s;
}

@import './80s.scss';
@import './glitch.scss';
@import './icons.scss';
