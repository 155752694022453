.glitch,
.glitch-disabled {
    color: white;
    display: inline-block;
    font-size: pxToRem(40);
    overflow: hidden;
    position: relative;
}

.glitch .Typewriter__cursor { // stylelint-disable-line
    visibility: hidden;
}

@keyframes noise-anim {
    $steps: 30;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100) +px, pxToRem(9999),random(100) +px,0);
        }
    }
}
.glitch::after {
    animation: noise-anim 2s infinite linear alternate-reverse;
    clip: rect(0, pxToRem(900),0,0);
    color: white;
    content: attr(data-text);
    left: pxToRem(1);
    overflow: hidden;
    position: absolute;
    text-shadow: pxToRem(-2) 0 red;
    top: 0;
}

@keyframes noise-anim-2 {
    $steps: 30;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100) +px, pxToRem(9999),random(100) +px,0);
        }
    }
}
.glitch::before {
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    clip: rect(0, pxToRem(900),0,0);
    color: white;
    content: attr(data-text);
    left: pxToRem(-2);
    overflow: hidden;
    position: absolute;
    text-shadow: pxToRem(1) 0 blue;
    top: 0;
}
