.icons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: pxToRem(20) 0 0 0;
}

.icon {
    margin-right: pxToRem(20);
    svg {
        fill: #d1d1d1;
        height: pxToRem(32);
        width: pxToRem(32);
    }

    &.github:hover,
    &.github a:focus {
        outline: none;
        svg {
            fill: #000;
        }
    }

    &.linkedin:hover,
    &.linkedin a:focus {
        outline: none;
        svg {
            fill: #0077b7;
        }
    }

    &.resume:hover,
    &.resume a:focus {
        outline: none;
        svg {
            fill: #f4a460;
        }
    }

}
